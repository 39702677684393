<template>
  <v-container v-if="data">
    <v-row class="pa-0 ma-0">
      <h3>Zahlungsinformationen</h3>
    </v-row>
    <!-- Show the model in the right types -->
    <v-row class="pa-0 ma-0"
    v-if="!(data.payment.type === 'OTP' &&
    (data.payment.product.type === 'candle' ||
    data.payment.product.type === 'marketing' ||
    data.payment.product.type === 'memorialProducts'))">
      <v-col class="pa-0 ma-0">
        Modell:
      </v-col>
      <v-col v-if="data.payment" class="pa-0 ma-0">
        {{data.payment.invoiceNumber.block}}
      </v-col>
    </v-row>
    <!-- methode, common on all types -->
    <v-row class="pa-0 ma-0">
      <v-col class="pa-0 ma-0">
        Methode:
      </v-col>
      <v-col v-if="data.payment" class="pa-0 ma-0">
        {{mapMethod()}}
      </v-col>
    </v-row>
    <!-- price, common on all types -->
    <v-row class="pa-0 ma-0">
      <v-col class="pa-0 ma-0">
        Preis:
      </v-col>
      <v-col v-if="data.payment" class="pa-0 ma-0">
        {{data.payment.amount/100}}€
      </v-col>
    </v-row>
    <!-- Show the last payment in the right types -->
    <v-row class="pa-0 ma-0"
    v-if="!(data.payment.type === 'OTP' &&
    (data.payment.product.type === 'candle') ||
    (data.payment.product.type === 'marketing') ||
    (data.payment.product.type === 'memorialProducts'))">
      <v-col class="pa-0 ma-0">
        Letzte Zahlung:
      </v-col>
      <v-col v-if="data.abo" class="pa-0 ma-0">
        {{new Date(data.abo.currentPeriod.start).toLocaleDateString('de-DE', {day: '2-digit', month: '2-digit', year: 'numeric'})}}
      </v-col>
      <v-col v-if="data.payment && !data.abo" class="pa-0 ma-0">
        {{new Date(data.payment._ct).toLocaleDateString('de-DE', {day: '2-digit', month: '2-digit', year: 'numeric'})}}
      </v-col>
    </v-row>
    <!-- Show the next payment in the right types -->
    <v-row class="pa-0 ma-0"
    v-if="data.payment &&
    !(data.payment.type === 'OTP' &&
    ((data.payment.product.type === 'candle') ||
    (data.payment.product.type === 'memorialPage') ||
    (data.payment.product.type === 'marketing') ||
    (data.payment.product.type === 'memorialProducts') ||
    (data.payment.product.type === 'memorialMail')))">
      <v-col class="pa-0 ma-0">
        Nächste Zahlung:
      </v-col>
      <v-col v-if="data.abo" class="pa-0 ma-0">
        {{new Date(data.abo.nextBillingDate).toLocaleDateString('de-DE', {day: '2-digit', month: '2-digit', year: 'numeric'})}}
      </v-col>
    </v-row>
    <!-- Show when expires in OTP memorialMail -->
    <v-row class="pa-0 ma-0"
    v-if="data.payment.type === 'OTP' &&
    data.payment.product.type === 'memorialMail'">
      <v-col class="pa-0 ma-0">
        Läuft aus am:
      </v-col>
      <v-col v-if="data.payment" class="pa-0 ma-0">
        {{new Date(data.payment.runtime.end).toLocaleDateString('de-DE', {day: '2-digit', month: '2-digit', year: 'numeric'})}}
      </v-col>
    </v-row>
    <!-- Show the date of the invoice in the right types -->
    <v-row class="pa-0 ma-0"
    v-if="!(data.payment.type === 'OTP' && ((data.payment.product.type === 'candle') ||
    (data.payment.product.type === 'memorialMail')||
    (data.payment.product.type === 'memorialPage')) ||
    (data.payment.type === 'ABO') && (data.payment.product.type === 'memorialPage'))">
      <v-col class="pa-0 ma-0">
        Rechnungsdatum:
      </v-col>
      <v-col v-if="data.payment" class="pa-0 ma-0">
        {{new Date(data.payment._ct).toLocaleDateString('de-DE', {day: '2-digit', month: '2-digit', year: 'numeric'})}}
      </v-col>
    </v-row>
    <!-- Show the id of the invoice in the right types -->
    <v-row class="pa-0 ma-0">
      <v-col class="pa-0 ma-0">
        Rechnungsnummer:
      </v-col>
      <v-col v-if="data.payment" class="pa-0 ma-0">
        {{data.payment.invoiceNumber.displayName}}
      </v-col>
    </v-row>
    <!-- Show the ABO Status in the Abo types -->
    <v-row class="pa-0 ma-0" v-if="data.abo">
      <v-col class="pa-0 ma-0">
        Abo-Status:
      </v-col>
      <v-col class="pa-0 ma-0">
        {{mapAboStatus()}}
      </v-col>
    </v-row>
    <!-- payment status, common on all types -->
    <v-row class="pa-0 ma-0">
      <v-col class="pa-0 ma-0">
        Status:
      </v-col>
      <v-col v-if="data.payment.status" class="pa-0 ma-0">
        {{mapPaymentStatus()}}
      </v-col>
    </v-row>
    <!-- external pay id -->
    <v-row class="pa-0 ma-0" v-if="externalPayId">
      <v-col class="pa-0 ma-0">
        External-Payment-ID:
      </v-col>
      <v-col class="pa-0 ma-0">
        {{externalPayId}}
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  props: ['data', 'externalPayId'],
  methods: {
    mapMethod () {
      this.$store.commit('MapMethod', this.data.payment.method)
      return this.$store.getters.method
    },
    mapAboStatus () {
      this.$store.commit('MapAboStatus', this.data.abo.status.type)
      return this.$store.getters.aboStatus
    },
    mapPaymentStatus () {
      this.$store.commit('MapPaymentStatus', this.data.payment.status.type)
      return this.$store.getters.paymentStatus
    }
  }
}
</script>
