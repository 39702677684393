<template>
  <div class="ma-0 pa-0">
    <v-container>
      <v-card class="unvergessen-shadow justify-center" max-width="1200px" style="margin: 0 auto">
        <!-- Name -->
        <div style="padding: 20px; text-align: center" class="grey3 title">
          <div style="position: relative">
            <div v-if="requestData.user">
              {{requestData.user.mail}}
            </div>
          </div>
        </div>
        <!-- Content -->
        <div>
          <v-container class="py-0">
            <v-row>
              <!-- Basic Info -->
              <v-col cols="6" class="user-col">
                <div class="ma-3">
                  <v-row class="ma-0 pa-0" v-if="requestData.user">
                    <v-col>
                      <v-row class="pa-0 ma-0">
                        <h4>Nutzerinformation:</h4>
                      </v-row>
                      <!-- Status -->
                      <v-row class="pa-0 ma-0">
                        <v-col class="pa-0 ma-0">
                          Status:
                        </v-col>
                        <v-col class="pa-0 ma-0" style="text-align: right">
                          {{ mapCrmPostStatus() }}
                        </v-col>
                      </v-row>
                      <!-- Cycle -->
                      <v-row class="pa-0 ma-0">
                        <v-col class="pa-0 ma-0">
                          Zyklus:
                        </v-col>
                        <v-col class="pa-0 ma-0" style="text-align: right">
                          {{ mapCrmPostCycle() }}
                        </v-col>
                      </v-row>
                      <!-- Created date -->
                      <v-row class="pa-0 ma-0">
                        <v-col class="pa-0 ma-0">
                          erstellt am:
                        </v-col>
                        <v-col class="pa-0 ma-0" style="text-align: right">
                          {{new Date(requestData.user.ct).toLocaleDateString('de-DE', {day: '2-digit', month: '2-digit', year: 'numeric'})}}
                        </v-col>
                      </v-row>
                      <!-- Finish date -->
                      <v-row class="pa-0 ma-0">
                        <v-col class="pa-0 ma-0">
                          Ende-Datum:
                        </v-col>
                        <v-col class="pa-0 ma-0" style="text-align: right">
                          {{new Date(requestData.user.et).toLocaleDateString('de-DE', {day: '2-digit', month: '2-digit', year: 'numeric'})}}
                        </v-col>
                      </v-row>
                      <v-row class="pa-0 ma-0 mt-5">
                        <h4>Orte:</h4>
                      </v-row>
                      <v-row v-for="(location, index) in requestData.user.locations" :key="index" class="pa-0 ma-0">
                        <v-col class="pa-0 ma-0" cols="1">
                          {{ index }}.
                        </v-col>
                        <v-col class="pa-0 ma-0" cols="7" style="text-align: right">
                          {{ location.name }}
                        </v-col>
                        <v-col class="pa-0 ma-0" style="text-align: right">
                          {{ location.radius.sur }} km
                        </v-col>
                      </v-row>
                      <v-row class="pa-0 ma-0 mt-12" style="">
                        <v-col cols="8" class="pa-0 ma-1">
                          <v-btn
                            class="grey2 text--text"
                            style="width: 100%"
                            @click="navigateToSettings()"
                          >
                            Einstellungen Bearbeiten
                          </v-btn>
                        </v-col>
                        <v-col cols="3" class="pa-0 ma-1">
                          <v-btn
                            class="error text--text"
                            style="width: 100%"
                            @click="openDeleteDialog"
                          >
                            Kündigen
                          </v-btn>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                </div>
              </v-col>
              <!-- Payment Info -->
              <v-col cols="6" class="user-col">
                <div class="pa-5">
                  <v-col v-if="requestData.payment">
                    <v-row>
                      <unvergessenPayInfo
                      class="pa-3"
                      :data = 'requestData'
                      :externalPayId = 'requestData.payment.externalPaymentID'
                      ></unvergessenPayInfo>
                    </v-row>
                    <v-row class="ma-0 pa-0">
                      <v-btn
                        class="grey2 text--text"
                        style="margin-bottom: 30px; width: 300px"
                        @click="goToPay()"
                      >
                        Zahlungseinstellungen
                      </v-btn>
                    </v-row>
                  </v-col>
                  <v-row class="pa-0 ma-0" v-else>
                    <h3 class="pa-0 ma-0">Keine Zahlungsinformationen vorhanden</h3>
                  </v-row>
                </div>
              </v-col>
              <!-- Events -->
              <v-col cols="6" class="user-col">
                <div class="ma-3">
                  <v-row class="pa-0 ma-0">
                    <h3 class="pa-0 ma-0">Vergangene Events</h3>
                  </v-row>
                </div>
              </v-col>
              <!-- Notification Events -->
              <v-col cols="6" class="user-col">
                <div class="ma-3">
                  <v-row class="pa-0 ma-0">
                    <h3 class="pa-0 ma-2">Zukünftige Events</h3>
                  </v-row>
                  <v-row class="pa-0 ma-0">
                    <v-col class="pa-0 ma-2">
                      <v-row v-for="(event, i) in events" :key="i" class="pa-0 ma-0">
                        <v-col class="pa-0 ma-0">
                          {{new Date(event.dueDate).toLocaleDateString('de-DE', {day: '2-digit', month: '2-digit', year: 'numeric'})}}
                        </v-col>
                        <v-col class="pa-0 ma-0">
                          GS{{' ' + event.mail + ' '}}Mail
                        </v-col>
                        <v-col class="pa-0 ma-0">
                          <v-icon color="success" v-if="event.processed && event.active">
                            mdi-check
                          </v-icon>
                          <v-icon v-if="event.processed && !event.active">
                            mdi-close
                          </v-icon>
                          <v-icon color="error" v-if="!event.processed && event.active" @click="openDialog(event, i, false)">
                            mdi-close
                          </v-icon>
                          <span v-if="!event.processed && !event.active" @click="openDialog(event, i, true)" style="cursor: pointer">
                            <u style="color: #febf7c">Reaktivieren</u>
                          </span>
                          <div v-if="!event.processed && event.active && event.mail === 'e3'" v-intersect='activeE3Btn'></div>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                  <v-row class="ma-0 pa-0">
                    <span style="cursor: pointer" v-if="e3btn" @click="openDialog(undefined, undefined, false)">
                      <u>Alle Mails außer E3 löschen</u>
                    </span>
                    <span v-else>
                      <u>Alle Mails außer E3 löschen</u>
                    </span>
                  </v-row>
                </div>
              </v-col>
            </v-row>
          </v-container>
        </div>
      </v-card>

      <v-card max-width="1200px" style="margin: 0 auto" class="unvergessen-shadow my-4">
        <!-- title -->
        <div style="padding: 20px; text-align: center" class="grey3 title">
          Notizen
        </div>

        <!-- Content -->
        <div>
          <v-container class="py-0">
            <v-row>
              <v-col cols="6" class="user-col">
                <v-textarea outlined class="py-0" v-model="editedNote" @input="isEditable()"></v-textarea>
                <div style="text-align: right">
                  <v-btn class="success" @click="editNote()" :disabled='editable'>
                    Speichern
                  </v-btn>
                </div>
              </v-col>
              <v-col cols="6" class="user-col">
              </v-col>
            </v-row>
          </v-container>
        </div>
      </v-card>
    </v-container>
    <!-- Dialogs -->
    <unvergessen-success-dialog
    :dialog="succesAlert"
    :buttonText="'Dialog schließen'"
    v-on:close="succesAlert = false">
      Die Anfrage ist erfolgreich
    </unvergessen-success-dialog>

    <v-dialog v-model="confirmDialog" max-width="400px" @click:outside="confirmDialog = false; errorAlert= false">
      <v-card class="pa-3">
        <v-alert
          type="error"
          v-model="errorAlert"
        >Error</v-alert>
        <div style="position: relative; height: 50px">
          {{dialogText}}
        </div>
        <v-row>
          <v-col>
            <v-btn @click="confirmDialog = false; errorAlert= false">
              Abbrechen
            </v-btn>
          </v-col>
          <v-col>
            <v-btn :color="buttonStatus" @click="desactiveEvent()">
              {{buttonText}}
            </v-btn>
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>

    <v-dialog max-width="500px" v-model="deleteDialog">
      <v-card style="padding: 20px">
        <h2>
          Möchtest du den User wirklich kündigen?
        </h2>
        <v-row style="text-align: center">
          <v-col>
            <v-btn class="error" width="100%" @click="deleteUser">
              Ja
            </v-btn>
          </v-col>
          <v-col>
            <v-btn width="100%" @click="closeDeleteDialog">
              Nein
            </v-btn>
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>

    <v-dialog max-width="500px" v-model="deletedUserDialog" @click:outside="closeDeletedUserDialog">
      <v-card style="padding: 20px">
        <p>
          Der Nutzer wurde erfolgreich gelöscht
        </p>
        <div style="text-align: center">
          <v-btn @click="closeDeletedUserDialog">
            Schließen
          </v-btn>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import axios from 'axios'
import PayInfo from '@/components/finance/accounting/PayInfo.vue'
import SuccessDialog from '@/components/global/SuccessDialog.vue'
export default {
  data () {
    return {
      urlPage: 'https://sandbox.eternio.de/Gedenkseite/',
      user: {},
      requestData: {
      },
      typeName: '',
      noteText: '',
      editedNote: '',
      editable: true,
      events: [],
      event: {},
      e3btn: false,
      // dialog data
      confirmDialog: false,
      dialogText: '',
      buttonText: '',
      status: false,
      succesAlert: false,
      errorAlert: false,
      deleteDialog: false,
      deletedUserDialog: false,
      errorDeletedUserDialog: false,
      currentIndex: 0,
      buttonStatus: 'success'
    }
  },
  methods: {
    navigateToSettings () {
      window.open('https://gedenk-post.unvergessen.de/Einstellungen?id=' + this.user.settingsId)
    },
    openDeleteDialog () {
      this.deleteDialog = true
    },
    closeDeleteDialog () {
      this.deleteDialog = false
    },
    closeDeletedUserDialog () {
      this.deletedUserDialog = false
      this.$router.push({ name: 'CRMMailUserCollection' })
    },
    deleteUser () {
      axios.delete('/crm/memorialMail/user', { data: { uid: this.uid } })
        .then(() => {
          this.deletedUserDialog = true
        })
        .catch(() => {
          this.errorDeletedUserDialog = false
        })
    },
    mapCrmPostStatus () {
      this.$store.commit('MapCrmPostStatus', this.requestData.user.status)
      return this.$store.getters.crmPostStatus
    },
    mapCrmPostCycle () {
      this.$store.commit('MapCrmPostCycle', this.requestData.user.cycle)
      return this.$store.getters.crmPostCycle
    },
    goToPay () {
      const route = this.$router.resolve({ name: 'AccountingUser', params: { uid: this.user.payment._id } })
      window.open(route.href)
    },
    openDialog (event, index, status) {
      this.confirmDialog = true
      this.event = event
      this.status = status
      if (event) {
        this.currentIndex = index
        if (status) {
          this.buttonText = 'Reaktivieren'
          this.dialogText = 'Möchtest du das Event reaktivieren?'
          this.buttonStatus = 'success'
        } else {
          this.buttonText = 'Event Löschen'
          this.dialogText = 'Möchtest du das Event wirklich löschen?'
          this.buttonStatus = 'error'
        }
      } else {
        this.buttonText = 'Alle Events Löschen'
        this.dialogText = 'Möchtest du alle Events außer E3 wirklich löschen?'
      }
    },
    activeE3Btn () {
      this.e3btn = true
    },
    desactiveEvent () {
      if (this.event) {
        axios
          .patch('/crm/memorialMail/user/event', { id: this.event._id, status: this.status, uID: this.uid })
          .then((res) => {
            this.events[this.currentIndex] = res.data[0]
            this.succesAlert = true
            this.confirmDialog = false
          })
          .catch(() => {})
      } else {
        axios
          .patch('/crm/memorialMail/user/deactivate', { uID: this.uid })
          .then((res) => {
            this.events = res.data
            this.succesAlert = true
            this.confirmDialog = false
          })
          .catch(() => {
            this.errorAlert = true
          })
      }
    },
    editNote () {
      axios.patch('/crm/memorialMail/user/notes', { uid: this.uid, notes: this.editedNote })
        .then(() => {
          this.noteText = this.editedNote
          this.editable = true
        })
        .catch(() => {})
    },
    isEditable () {
      if (this.noteText === this.editedNote) {
        this.editable = true
      } else {
        this.editable = false
      }
    },
    deleteEvent (item) {
      // TODO
      console.log('deleteEvent: ' + item)
    },
    openInvoice (item) {
      // TODO
      console.log('openInvoice: ' + item)
    },
    loadMore () {
      // TODO
    }
  },
  computed: {
    uid () {
      return this.$route.params.uid
    }
  },
  created () {
    axios.get('/crm/memorialMail/user', { params: { id: this.uid } })
      .then(res => {
        this.user = res.data.user
        this.requestData.user = res.data.user
        this.requestData.payment = res.data.payment
        this.requestData.abo = res.data.abo
        this.noteText = res.data.user.notes
        this.editedNote = this.noteText
        this.events = res.data.events
      })
      .catch(() => {})
  },
  components: {
    unvergessenPayInfo: PayInfo,
    unvergessenSuccessDialog: SuccessDialog
  }
}
</script>

<style>
.user-col {
  border-style: solid;
  border-width: 1px;
  border-color: #cccccc
}
</style>
