var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.data)?_c('v-container',[_c('v-row',{staticClass:"pa-0 ma-0"},[_c('h3',[_vm._v("Zahlungsinformationen")])]),(!(_vm.data.payment.type === 'OTP' &&
  (_vm.data.payment.product.type === 'candle' ||
  _vm.data.payment.product.type === 'marketing' ||
  _vm.data.payment.product.type === 'memorialProducts')))?_c('v-row',{staticClass:"pa-0 ma-0"},[_c('v-col',{staticClass:"pa-0 ma-0"},[_vm._v(" Modell: ")]),(_vm.data.payment)?_c('v-col',{staticClass:"pa-0 ma-0"},[_vm._v(" "+_vm._s(_vm.data.payment.invoiceNumber.block)+" ")]):_vm._e()],1):_vm._e(),_c('v-row',{staticClass:"pa-0 ma-0"},[_c('v-col',{staticClass:"pa-0 ma-0"},[_vm._v(" Methode: ")]),(_vm.data.payment)?_c('v-col',{staticClass:"pa-0 ma-0"},[_vm._v(" "+_vm._s(_vm.mapMethod())+" ")]):_vm._e()],1),_c('v-row',{staticClass:"pa-0 ma-0"},[_c('v-col',{staticClass:"pa-0 ma-0"},[_vm._v(" Preis: ")]),(_vm.data.payment)?_c('v-col',{staticClass:"pa-0 ma-0"},[_vm._v(" "+_vm._s(_vm.data.payment.amount/100)+"€ ")]):_vm._e()],1),(!(_vm.data.payment.type === 'OTP' &&
  (_vm.data.payment.product.type === 'candle') ||
  (_vm.data.payment.product.type === 'marketing') ||
  (_vm.data.payment.product.type === 'memorialProducts')))?_c('v-row',{staticClass:"pa-0 ma-0"},[_c('v-col',{staticClass:"pa-0 ma-0"},[_vm._v(" Letzte Zahlung: ")]),(_vm.data.abo)?_c('v-col',{staticClass:"pa-0 ma-0"},[_vm._v(" "+_vm._s(new Date(_vm.data.abo.currentPeriod.start).toLocaleDateString('de-DE', {day: '2-digit', month: '2-digit', year: 'numeric'}))+" ")]):_vm._e(),(_vm.data.payment && !_vm.data.abo)?_c('v-col',{staticClass:"pa-0 ma-0"},[_vm._v(" "+_vm._s(new Date(_vm.data.payment._ct).toLocaleDateString('de-DE', {day: '2-digit', month: '2-digit', year: 'numeric'}))+" ")]):_vm._e()],1):_vm._e(),(_vm.data.payment &&
  !(_vm.data.payment.type === 'OTP' &&
  ((_vm.data.payment.product.type === 'candle') ||
  (_vm.data.payment.product.type === 'memorialPage') ||
  (_vm.data.payment.product.type === 'marketing') ||
  (_vm.data.payment.product.type === 'memorialProducts') ||
  (_vm.data.payment.product.type === 'memorialMail'))))?_c('v-row',{staticClass:"pa-0 ma-0"},[_c('v-col',{staticClass:"pa-0 ma-0"},[_vm._v(" Nächste Zahlung: ")]),(_vm.data.abo)?_c('v-col',{staticClass:"pa-0 ma-0"},[_vm._v(" "+_vm._s(new Date(_vm.data.abo.nextBillingDate).toLocaleDateString('de-DE', {day: '2-digit', month: '2-digit', year: 'numeric'}))+" ")]):_vm._e()],1):_vm._e(),(_vm.data.payment.type === 'OTP' &&
  _vm.data.payment.product.type === 'memorialMail')?_c('v-row',{staticClass:"pa-0 ma-0"},[_c('v-col',{staticClass:"pa-0 ma-0"},[_vm._v(" Läuft aus am: ")]),(_vm.data.payment)?_c('v-col',{staticClass:"pa-0 ma-0"},[_vm._v(" "+_vm._s(new Date(_vm.data.payment.runtime.end).toLocaleDateString('de-DE', {day: '2-digit', month: '2-digit', year: 'numeric'}))+" ")]):_vm._e()],1):_vm._e(),(!(_vm.data.payment.type === 'OTP' && ((_vm.data.payment.product.type === 'candle') ||
  (_vm.data.payment.product.type === 'memorialMail')||
  (_vm.data.payment.product.type === 'memorialPage')) ||
  (_vm.data.payment.type === 'ABO') && (_vm.data.payment.product.type === 'memorialPage')))?_c('v-row',{staticClass:"pa-0 ma-0"},[_c('v-col',{staticClass:"pa-0 ma-0"},[_vm._v(" Rechnungsdatum: ")]),(_vm.data.payment)?_c('v-col',{staticClass:"pa-0 ma-0"},[_vm._v(" "+_vm._s(new Date(_vm.data.payment._ct).toLocaleDateString('de-DE', {day: '2-digit', month: '2-digit', year: 'numeric'}))+" ")]):_vm._e()],1):_vm._e(),_c('v-row',{staticClass:"pa-0 ma-0"},[_c('v-col',{staticClass:"pa-0 ma-0"},[_vm._v(" Rechnungsnummer: ")]),(_vm.data.payment)?_c('v-col',{staticClass:"pa-0 ma-0"},[_vm._v(" "+_vm._s(_vm.data.payment.invoiceNumber.displayName)+" ")]):_vm._e()],1),(_vm.data.abo)?_c('v-row',{staticClass:"pa-0 ma-0"},[_c('v-col',{staticClass:"pa-0 ma-0"},[_vm._v(" Abo-Status: ")]),_c('v-col',{staticClass:"pa-0 ma-0"},[_vm._v(" "+_vm._s(_vm.mapAboStatus())+" ")])],1):_vm._e(),_c('v-row',{staticClass:"pa-0 ma-0"},[_c('v-col',{staticClass:"pa-0 ma-0"},[_vm._v(" Status: ")]),(_vm.data.payment.status)?_c('v-col',{staticClass:"pa-0 ma-0"},[_vm._v(" "+_vm._s(_vm.mapPaymentStatus())+" ")]):_vm._e()],1),(_vm.externalPayId)?_c('v-row',{staticClass:"pa-0 ma-0"},[_c('v-col',{staticClass:"pa-0 ma-0"},[_vm._v(" External-Payment-ID: ")]),_c('v-col',{staticClass:"pa-0 ma-0"},[_vm._v(" "+_vm._s(_vm.externalPayId)+" ")])],1):_vm._e()],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }